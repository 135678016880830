export const claimMessageGenerator = (mailData, action, name) => {
    const lineOne = `<p>Dear ${name},</p>`;
    const actionMessage =
        action === "approval"
            ? mailData.status
                ? "Your claim has been approved. "
                : "Your claim has been rejected. "
            : `A new expense claim has been submitted by: ${mailData.name}. `;

    const lineTwo = `<p>${actionMessage + "Here is the details:"}</p>`;
    const lineThree = `<i><ul>
    <li>Employee ID: ${mailData.empID}</li>
    <li>Claim: ${mailData.title}</li>
    <li>Amount: ${mailData.amount}</li>
    <li>Status: ${mailData.status}</li>
    </ul></i>`;
    const lineFour = `<p>Click here to check the details: <a href="https://hr.myelinh.com" target="_blank" >https://hr.myelinh.com</a> </p>`;
    const mailBody = lineOne + lineTwo + lineThree + lineFour;
    const mailSubject = "Expense Claim Status | Myelin-H HR";
    const mailDetails = { ...mailData, mailBody, mailSubject };
    // alert(mailBody)
    return mailDetails;
};

export const documentUploadMessage = (data, type) => {
    const lineOne = `<p>Dear ${data.name},</p>`;
    const lineTwo = `<p>A new ${type} has been added to your profile by admin.</p>`;
    const lineThree = `<p>Please click here to view: <a href="${data.link}" target="_blank">get details</a> </p>`;
    const lineFour = `<p>Thank you!</p>`;
    const mailBody = lineOne + lineTwo + lineThree + lineFour;
    const mailSubject = "New Document added to your profile | Myelin-H HR";
    const mailDetails = { ...data, mailBody, mailSubject };

    return mailDetails;
};

export const assignedNewTaskMessage = (data, type) => {
    const lineOne = `<p>Dear team,</p>`;
    const lineTwo = `<p>A new task has been assigned to you.</p>`;
    const lineThree = `<p><b>Task title: ${data.title}</b></p>`;
    const lineFour = `<i>${
        data.details ? data.details : "--details to be added--"
    }</i> <br/>`;
    const lineFive = `<p>Please click here to view: <a href="https://hr.myelinh.com/assigned-tasks" target="_blank">get details</a> </p>`;
    const lineSix = `<p>Thank you. <br/><br/>Regards,<br/>Myelin-H HR</p>`;
    const mailBody =
        lineOne + lineTwo + lineThree + lineFour + lineFive + lineSix;
    const mailSubject = "A new task has been assigned to you";
    const mailDetails = { ...data, mailBody, mailSubject };

    return mailDetails;
};
export const sendTaskReminderMessage = (data, type) => {
    const lineOne = `<p>Dear team,</p>`;
    const lineTwo = `<p>It's been more than a week since the following task was assigned to you, but it appears that no progress has been made yet. If there are any confusions or issues, please reach out as soon as possible so we can address them and move forward.</p>`;
    const lineThree = `<p><b>Task title: ${data.title}</b></p>`;
    const lineFour = `<i>${
        data.details ? data.details : "--details to be added--"
    }</i> <br/>`;
    const lineFive = `<p>Please click here to view: <a href="https://hr.myelinh.com/assigned-tasks" target="_blank">get details</a> </p>`;
    const lineSix = `<p>Thank you. <br/><br/>Regards,<br/>Myelin-H HR</p>`;
    const mailBody =
        lineOne + lineTwo + lineThree + lineFour + lineFive + lineSix;
    const mailSubject = "A new task has been assigned to you";
    const mailDetails = { ...data, mailBody, mailSubject };

    return mailDetails;
};
export const newCommentOnTheTask = (data, type) => {
    const lineOne = `<p>Dear team,</p>`;
    const lineTwo = `<p><b>${data.commenter}</b> has added the following new comment to the task, <b>${data.taskTitle}</b>.</p>`;
    const lineThree = `<p><u> <i>${data.commentText}</i></u></p>`;
    const lineFive = `<p>Please click here to view: <a href="https://hr.myelinh.com/assigned-tasks" target="_blank">get details</a> </p>`;
    const lineSix = `<p>Thank you. <br/><br/>Regards,<br/>Myelin-H HR</p>`;
    const mailBody = lineOne + lineTwo + lineThree + lineFive + lineSix;
    const mailSubject = `${data.taskTitle} | New Comment`;
    const mailDetails = { ...data, mailBody, mailSubject };

    return mailDetails;
};
export const newAttachmentAddedNotification = (data, type) => {
    const lineOne = `<p>Dear team,</p>`;
    const lineTwo = `<p><b>${data.commenter}</b> has added the following new <b>attachment</b> to the task, <b>${data.taskTitle}</b>.</p>`;
    const lineThree = `<p><u> <i>Click here to view the attachment: <a href="${data.attachmentUrl}" target="_blank">${data.attachmentUrl}</a></i></u></p>`;
    const lineFive = `<p>Please click here to view: <a href="https://hr.myelinh.com/assigned-tasks" target="_blank">get details</a> </p>`;
    const lineSix = `<p>Thank you. <br/><br/>Regards,<br/>Myelin-H HR</p>`;
    const mailBody = lineOne + lineTwo + lineThree + lineFive + lineSix;
    const mailSubject = `${data.taskTitle} | New Attachment`;
    const mailDetails = { ...data, mailBody, mailSubject };

    return mailDetails;
};
export const newCandidateAddedNotification = (data, type) => {
    const lineOne = `<p>Dear concern,</p>`;
    const lineTwo = `<p>A new candidate has been added to the recruitment portal for you to assess and put score.</p>`;
    const lineThree = `<p>Job Position: ${data.positionTitle}</p>`;
    const lineFive = `<p>Please click here to view: <a href="https://hr.myelinh.com/recruitment" target="_blank">get details</a> </p>`;
    const lineSix = `<p>Thank you. <br/><br/>Regards,<br/>Myelin-H HR</p>`;
    const mailBody = lineOne + lineTwo + lineThree + lineFive + lineSix;
    const mailSubject = `New Candidate to Assess for ${data.positionTitle} | Myelin-H Recruitment`;
    const mailDetails = { ...data, mailBody, mailSubject };

    return mailDetails;
};

export const bankAccountUpdatedIndividual = (data) => {
    const lineOne = `<p>Dear Zied,</p>`;
    const lineTwo = `<p>${data.name} has just change the bank details. <br/>Please check.</p>`;
    const lineThree = `<br/><br/><p>Thank you. <br/><br/>Regards,<br/>Myelin-H HR</p>`;
    const mailBody = lineOne + lineTwo + lineThree;

    const mailSubject = "Employee Bank Details Update | Myelin-H HR";
    const mailDetails = { ...data, mailBody, mailSubject };
    return mailDetails;
};
export const meetingNotesAssignmentMessage = (data, type) => {
    const date = new Date(data.meetingDate);
    const shortDateString = `${date.getDate()}/${
        date.getMonth() + 1
    }/${date.getFullYear()}`;

    const lineOne = `<p>Dear ${data.assignedTo},</p>`;
    const lineTwo = `<p>You have been assigned to take notes of the team meeting on ${shortDateString}.</p>`;
    const mailSubject = "Take Meeting Notes This Week | Myelin-H HR";
    const lineThree = `<p>Please visit: <a href="https://hr.myelinh.com/meeting-notes" target="_blank" rel="noopener noreferrer">https://hr.myelinh.com/meeting-notes</a>
    <br/><br/>Thank you!</p>`;
    const lineFour = `<p><br/><br/> Regards, <br/>Myelin-H HR</p>`;
    const mailBody = lineOne + lineTwo + lineThree + lineFour;
    const mailDetails = { ...data, mailBody, mailSubject };

    return mailDetails;
};

export const documentUploadConfirmationMessage = (data) => {
    const mailSubject = `New Document Uploaded | Myelin-H HR`;
    const lineOne = `<p>Dear concern,</p>`;
    const lineTwo = `<p>A new document has been uploaded. Please check the following details:</p>`;
    const lineThree = `<ul>
    <li>Name: ${data.fileName}</li>
    <li>Type: ${data.fileType}</li>
    <li>File URL: <a target="_blank" rel="noopener noreferrer" href="${data.fileURL}">click here</a></li>
    </ul>`;
    const lineFour = `<p>Please visit: <a href="https://hr.myelinh.com/personal-profile" target="_blank" rel="noopener noreferrer">https://hr.myelinh.com/personal-profile</a>
    <br/><br/>Thank you!</p> <p><br/><br/> Regards, <br/>Myelin-H HR</p> `;
    const mailBody = lineOne + lineTwo + lineThree + lineFour;
    const mailDetails = { ...data, mailBody, mailSubject };

    return mailDetails;
};

// cc mails: {{mailTwo}};{{mailThree}};{{mailFour}}
